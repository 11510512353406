import React from 'react';
import { Link } from 'gatsby';

import IconTypes from '../../data/types/IconTypes';

import * as styles from './FeatureBlock.module.scss';

interface FeatureBlockProps {
    icon: {
        contentfulid: string
    } | string,
    heading: string,
    headingClassName?: 'text-label'|'text-subheading'|'text-heading',
    slug: string,
    description: {
        description: string
    } | string,
    descriptionClassName?: 'text-metadata'|'text-paragraph'|'grow',
    readMoreTarget?: {
        slug: string
    },
    spaceY?: 1|2|3,
}

const FeatureBlock = ({
    icon, heading, headingClassName = 'text-label', description, descriptionClassName = 'text-metadata', slug, spaceY = 1, readMoreTarget
}: FeatureBlockProps): JSX.Element => {
    const IconId = (typeof icon === 'object') ? icon.contentfulid : icon;
    const IconComponent = IconTypes[IconId];

    return (
        <div id={slug} className={`${styles.FeatureBlock} space-y-${spaceY}`}>
            <div className="flex gap-1 items-start max-w-md">
                {(IconComponent) ? <IconComponent size="32px" className="min-w-[32px]" /> : null}
                <div>
                    <span className={headingClassName}>{heading}</span>
                    <p className={descriptionClassName}>{(typeof description === 'object') ? description.description : description}</p>
                </div>
            </div>
            {
                (readMoreTarget)
                    ? (
                        <div className={styles.FeatureBlockReadMore}>
                            <Link to={`#${readMoreTarget.slug}`} className="inline-block text-smallcaps text-underline text-bronze-400">Read more</Link>
                        </div>
                    )
                    : null
            }
        </div>
    );
};

export default FeatureBlock;
