import React from 'react';

import * as styles from './TrustBlock.module.scss';

import { FeatureBlockEntity } from '../../types/contentfulModels/FeatureBlockEntity';

import FeatureBlock from '../FeatureBlock/FeatureBlock';

interface TrustBlockProps {
    features: FeatureBlockEntity[]
}

const TrustBlock = ({ features }: TrustBlockProps): JSX.Element => (
    <div className={styles.TrustBlock}>
        {
            features.map((feature) => (
                <FeatureBlock
                    key={feature.slug}
                    slug={feature.slug}
                    icon={feature.icon.contentfulid}
                    heading={feature.heading}
                    headingClassName="text-subheading"
                    description={feature.description}
                />
            ))
        }
    </div>
);

export default TrustBlock;
