import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';

import { FeatureBlockEntity } from '../types/contentfulModels/FeatureBlockEntity';

import CarbonCallout from '../components/CarbonCallout/CarbonCallout';
import FeatureBlock from '../components/FeatureBlock/FeatureBlock';
import Decorator from '../components/Decorator/Decorator';
import Hero from '../components/Hero/Hero';
import ProjectCallout from '../components/ProjectCallout/ProjectCallout';
import ProjectList, { ProjectRepeaterEntity } from '../components/ProjectList/ProjectList';
import QuoteBlock from '../components/QuoteBlock/QuoteBlock';
import SummaryBlock from '../components/SummaryBlock/SummaryBlock';
import TrustBlock from '../components/TrustBlock/TrustBlock';
import IconPlay from '../components/Icons/IconPlay';
import IconArrowRight from '../components/Icons/IconArrowRight';
import LinkButton from '../components/Buttons/LinkButton/LinkButton';
import ampersandImg from '../images/ampersand.svg';
import diamondImg from '../images/diamond.svg';
import logoFullWhiteImg from '../images/logo--full--white.svg';
import Seo from '../components/Seo/Seo';
import { VimeoEntity } from '../types/contentfulModels/VimeoEntity';


export interface HomepageProps {
    data: {
        contentfulGenericPage: {
            title: string,
            heroImage?: {
                url: string
            }
            heroVideo?: VimeoEntity,
            heroVideoStartTime?: number,
            pageBody: {
                projects: ProjectRepeaterEntity,
                featureBlocks: FeatureBlockEntity[]
            }
        };
        contentfulProject?: {
            slug: string
        }
    };
}

const HomePage = ({ data }: HomepageProps): JSX.Element => {
    const { title: pageTitle, heroVideo } = data.contentfulGenericPage;
    const heroImage = (data.contentfulGenericPage.heroImage) ? `${data.contentfulGenericPage.heroImage.url}?fm=webp&w=1920` : undefined;

    const projectRepeaterLarge = data.contentfulGenericPage.pageBody[0];
    // const projectRepeaterMedium = data.contentfulGenericPage.pageBody[1];
    // const projectRepeaterSmall = data.contentfulGenericPage.pageBody[1];
    const trustBlockFeatures = data.contentfulGenericPage.pageBody[1].featureBlocks;

    const featuredProject = (data.contentfulProject) ? data.contentfulProject : undefined;

    return (
        <Fragment>
            <Seo title={pageTitle} />
            <Hero height="medium" background={heroImage} videoData={heroVideo} className="pb-32">
                <Hero.Body containerWidth="large" className="md:items-end">
                    <div className="space-y-1">
                        <Hero.Title titleType="subtitle"><span className="gold valign--center">Individual building solutions <img src={ampersandImg} alt="&" style={{ width: '1.25em', lineHeight: 1 }} /> comprehensive service</span></Hero.Title>
                        <div className="md:hidden">
                            <LinkButton href="/project-planner" label="Start your project" icon={<IconArrowRight size="24px" stroke="slate-900" />} />
                        </div>
                    </div>
                    {
                        (featuredProject)
                            ? (
                                <Hero.Footer containerWidth="large" justifyItems="center-right">
                                    <Link to={`/projects/${featuredProject.slug}`} className="space-x-1 valign--center">
                                        <span className="text-smallcaps">View latest project</span>
                                        <IconPlay size="24px" />
                                    </Link>
                                </Hero.Footer>
                            )
                            : null
                    }
                </Hero.Body>
            </Hero>
            <div className="space-y-7 -mt-32 relative">
                <section className="contain containLarge grid md:grid-cols-2 gap-2 space-y-4 md:space-y-0">
                    <SummaryBlock>
                        <SummaryBlock.Intro
                            heading="Design"
                            body="We work with you to design the perfect solution for your project. From sourcing architect's drawings to final finishes and technical interactions that bring a project to life. We manage all the moving parts for you."
                            linkTo="/design"
                            linkLabel="Explore the design phase"
                        />
                        <SummaryBlock.Features>
                            <FeatureBlock
                                slug="fully-insured"
                                icon="IconContract"
                                heading="Planning and Building Control"
                                headingClassName="text-subheading"
                                description="We can help guide you through the process of applying for planning approval and ensure that inspector visits are taken care of and that sign off is provided."
                                descriptionClassName="text-paragraph"
                            />
                            <FeatureBlock
                                slug="fully-insured-2"
                                icon="IconPlans"
                                heading="Partnered with Architects"
                                headingClassName="text-subheading"
                                description="We work very closely with a number of reputable architects who can help develop your projects scheme down to the finest detail."
                                descriptionClassName="text-paragraph"
                            />
                            <FeatureBlock
                                slug="fully-insured-3"
                                icon="IconContractApproved"
                                heading="Engineers Specification"
                                headingClassName="text-subheading"
                                description="We can engage reputable engineers to provide all structural specifications in hand with the architectural scheme."
                                descriptionClassName="text-paragraph"
                            />
                            <FeatureBlock
                                slug="fully-insured-4"
                                icon="IconBudget"
                                heading="Budget Costing"
                                headingClassName="text-subheading"
                                description="We can provide budget costs throughout the design phase, so that any expectations are managed from early on."
                                descriptionClassName="text-paragraph"
                            />
                        </SummaryBlock.Features>
                    </SummaryBlock>
                    <SummaryBlock>
                        <SummaryBlock.Intro
                            heading="Build"
                            body="Our experienced team of specialists are fully qualified and insured to deliver your project to the highest standard. We work with you to ensure your project is delivered on time and on budget."
                            linkTo="/build"
                            linkLabel="Explore the build phase"
                        />
                        <SummaryBlock.Features>
                            <FeatureBlock
                                slug="fully-insured-8"
                                icon="IconTaskList"
                                heading="Project Programme"
                                headingClassName="text-subheading"
                                description="We provide a project programme ahead of time to ensure that the pace of work remains efficient and that project milestones are met."
                                descriptionClassName="text-paragraph"
                            />
                            <FeatureBlock
                                slug="fully-insured-5"
                                icon="IconRibbon"
                                heading="Qualified and Experienced"
                                headingClassName="text-subheading"
                                description="Our qualified site managers and supervisors will ensure your project is delivered to the highest standard."
                                descriptionClassName="text-paragraph"
                            />
                            <FeatureBlock
                                slug="fully-insured-6"
                                icon="IconBusinessDeal"
                                heading="Specialist Subcontractors"
                                headingClassName="text-subheading"
                                description="We work with tried and tested specialist subcontractors who can provide a wide range of top tier skills."
                                descriptionClassName="text-paragraph"
                            />
                            <FeatureBlock
                                slug="fully-insured-7"
                                icon="IconPhone"
                                heading="Contract Completion / Retention Period"
                                headingClassName="text-subheading"
                                description="Practical completion is issued by a professional third party and all snags are managed under contract before the final retention payment."
                                descriptionClassName="text-paragraph"
                            />
                        </SummaryBlock.Features>
                    </SummaryBlock>
                </section>
                <section className="contain containLarge space-y-7">
                    <header className="text-center max-w-0.25 m-auto space-y-4">
                        <h2 className="text-subtitle">Our projects</h2>
                        <Decorator />
                        <p className="text-paragraph">See our portfolio of our comprehensive design and build projects.</p>
                    </header>
                </section>
                <section className="contain containLarge space-y-5">
                    <ProjectList spacing={7} projectRepeater={projectRepeaterLarge} />
                    { /* <ProjectList spacing={7} projectRepeater={projectRepeaterMedium} /> */}
                    { /* <ProjectList spacing={7} projectRepeater={projectRepeaterSmall} /> */}
                </section>
                <section className="contain containMedium space-y-5">
                    <div className="text-center">
                        <Decorator />
                    </div>
                    <div className="max-w-0.5 m-auto">
                        <QuoteBlock
                            author=""
                            quote="We are a conscientious building business focused on providing high-quality, fine finish construction work."
                        />
                    </div>
                    <div className="max-w-0.5 m-auto space-y-1">
                        <p className="text-paragraph"> Luxury Constructions provide an unparalleled benchmark in quality amidst our competitors. There is nearly 300 years' worth of experience in our staff team.
                            Our employees and subcontractors have been handpicked one at a time following the company's birth, and we now have a top tier team of reliable, courteous and conscientious tradesman -
                            able to take on all elements of general building work. We want to improve the face of the local building industry - providing customers with a professional and reliable name to turn to
                            for all domestic building needs. Each of our tradesman is educated, approachable, helpful and experienced. We are far from the builder cliche, our builders are people you would be happy
                            to have in your home, who are respectful and polite.
                        </p>
                        <p className="text-paragraph"> We believe our work ethic and attention to detail set us apart from our local competitors. We have a fantastic local reputation, as well as a steady amount of repeat custom.
                            We pride ourselves as a team on customer focus and high-quality craftsmanship, working with a high level of technicality as a standard.
                        </p>
                        <p className="text-paragraph"> We always provide a realistic schedule whereby Luxury Constructions coordinate the trades - time effectively. Providing our customers with an end to end service, and minimal upheaval.</p>
                        <p className="text-paragraph"> Our quotes are competitive, and above all, we offer our customers professional high-end results. We can confidently say - we are the builders that care.</p>
                        <p className="text-paragraph">Please also take a look at our Carbon Manifesto to learn more about our company's deeply imbued commitment to preserving our environment.</p>

                    </div>
                    <TrustBlock features={trustBlockFeatures} />
                    <div className="text-center space-y-3">
                        <img src={logoFullWhiteImg} alt="Luxury constructions" />
                        <div className="flex align-center space-x-1 justify-center">
                            <span className="text-smallcaps">Est 2012</span>
                            <img src={diamondImg} alt="A decorative gold diamond shape" />
                            <span className="text-smallcaps">Cheltenham, UK</span>
                        </div>
                    </div>
                    <div className="text-center">
                        <Decorator />
                    </div>
                </section>
            </div>
            <ProjectCallout />
            <CarbonCallout />
        </Fragment>
    );
};

export const pageQuery = graphql`
query HomepageContentQuery {
    contentfulGenericPage(slug: {eq: "homepage"}) {
        title
        heroImage {
            url
        }
        pageBody {
          ... on ContentfulProjectListRepeater {
            id
            type
            projects {
              id
              tags
              title
              heroImage {
                id
                gatsbyImageData(
                    width: 1920,
                    layout: FULL_WIDTH
                )
                description
              }
              description {
                  raw
              }
              slug
            }
          }
          ... on ContentfulTrustBlock {
            id
            featureBlocks {
              heading
              slug
              icon {
                name
                contentfulid
              }
              description {
                description
              }
            }
          }
        }
      }
      contentfulProject(projectFeatured: {eq: true}) {
        slug
      }
  }
`;

export default HomePage;
