import React from 'react';

import * as styles from './SummaryBlock.module.scss';

import LinkButton from '../Buttons/LinkButton/LinkButton';

interface SummaryBlockIntroProps {
    heading: string,
    body: string,
    linkLabel: string,
    linkTo: string
}

const SummaryBlockIntro = ({
    heading, body, linkLabel, linkTo
}: SummaryBlockIntroProps): JSX.Element => (
    <div className={styles.SummaryBlockIntro}>
        <div className="max-w-0.2 space-y-2">
            <h2 className="text-subtitle">{heading}</h2>
            <p className="text-metadata">{body}</p>
            <LinkButton href={linkTo} label={linkLabel} bordered />
        </div>
    </div>
);

export default SummaryBlockIntro;
