import React from 'react';

import * as styles from './SummaryBlock.module.scss';

import SummaryBlockIntro from './SummaryBlockIntro';
import SummaryBlockFeatures from './SummaryBlockFeatures';

interface SummaryBlockProps {
    children: React.ReactNode,
    reverseLayout?: boolean
}

const SummaryBlock = ({ children, reverseLayout = false }: SummaryBlockProps): JSX.Element => (
    <article className={`${styles.SummaryBlock} ${(reverseLayout) ? 'flex-row-reverse' : ''}`}>
        {children}
    </article>
);

SummaryBlock.Intro = SummaryBlockIntro;
SummaryBlock.Features = SummaryBlockFeatures;

export default SummaryBlock;
