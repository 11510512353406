import React from 'react';

import * as styles from './SummaryBlock.module.scss';

interface SummaryBlockFeaturesProps {
    children: React.ReactNode
}

const SummaryBlockFeatures = ({ children }: SummaryBlockFeaturesProps): JSX.Element => (
    <div className={styles.SummaryBlockFeatures}>
        {children}
    </div>
);

export default SummaryBlockFeatures;
